import React from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { NavLink, Outlet } from 'react-router-dom'
import { FaRegUser } from 'react-icons/fa';
import { useSelector } from 'react-redux';








const SideNav = () => {
    const toggle = useSelector((state) => state.toggleReducers);


  return (
    <React.Fragment>
      <aside className={`property side-nav admin-sidenav ${toggle === true ? "active" : ""}`}>

        <div className="side-nav-menu">
          <div className="sec">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <ul>
                    <li className="deactive"><NavLink to="/admin/userManagement" ><span className="image-nav"><FaRegUser /></span><span className="img-active"><FaRegUser /></span>User Management <i className="fa fa-angle-right" aria-hidden="true" /></NavLink>
                    </li>

                  </ul>
                </Accordion.Header>

              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <ul>
                    <li className="deactive"><NavLink to="/admin/addPoliticians" ><span className="image-nav"><FaRegUser /></span><span className="img-active"><FaRegUser /></span>Add Politicians<i className="fa fa-angle-right" aria-hidden="true" /></NavLink>
                    </li>

                  </ul>
                </Accordion.Header>

              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <ul>
                    <li className="deactive"><NavLink to="/admin/addTopGovtServant" ><span className="image-nav"><FaRegUser /></span><span className="img-active"><FaRegUser /></span>Add Govt Servant<i className="fa fa-angle-right" aria-hidden="true" /></NavLink>
                    </li>

                  </ul>
                </Accordion.Header>

              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <ul>
                    <li className="deactive"><NavLink to="/admin/topStudents" ><span className="image-nav"><FaRegUser /></span><span className="img-active"><FaRegUser /></span>Top Students<i className="fa fa-angle-right" aria-hidden="true" /></NavLink>
                    </li>

                  </ul>
                </Accordion.Header>

              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <ul>
                    <li className="deactive"><NavLink to="/admin/addYouthIcons" ><span className="image-nav"><FaRegUser /></span><span className="img-active"><FaRegUser /></span>Add Youth Icons<i className="fa fa-angle-right" aria-hidden="true" /></NavLink>
                    </li>

                  </ul>
                </Accordion.Header>

              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  <ul>
                    <li className="deactive"><NavLink to="/admin/addHelpPeople" ><span className="image-nav"><FaRegUser /></span><span className="img-active"><FaRegUser /></span>Add Help People<i className="fa fa-angle-right" aria-hidden="true" /></NavLink>
                    </li>

                  </ul>
                </Accordion.Header>

              </Accordion.Item>
              <Accordion.Item eventKey="10">
                <Accordion.Header>
                  <ul>
                    <li className="deactive"><NavLink to="/admin/addFeedback" ><span className="image-nav"><FaRegUser /></span><span className="img-active"><FaRegUser /></span>Add Feedback<i className="fa fa-angle-right" aria-hidden="true" /></NavLink>
                    </li>

                  </ul>
                </Accordion.Header>

              </Accordion.Item>
              <Accordion.Item eventKey="11">
                <Accordion.Header>
                  <ul>
                    <li className="deactive"><NavLink to="/admin/addCamp" ><span className="image-nav"><FaRegUser /></span><span className="img-active"><FaRegUser /></span>Add Camp<i className="fa fa-angle-right" aria-hidden="true" /></NavLink>
                    </li>

                  </ul>
                </Accordion.Header>

              </Accordion.Item>
              <Accordion.Item eventKey="12">
                <Accordion.Header>
                  <ul>
                    <li className="deactive"><NavLink to="/admin/addCongrats" ><span className="image-nav"><FaRegUser /></span><span className="img-active"><FaRegUser /></span>Add Congrats<i className="fa fa-angle-right" aria-hidden="true" /></NavLink>
                    </li>

                  </ul>
                </Accordion.Header>

              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  <ul>
                    <li className="deactive"><NavLink to="/admin/addEvents" ><span className="image-nav"><FaRegUser /></span><span className="img-active"><FaRegUser /></span>Our Events<i className="fa fa-angle-right" aria-hidden="true" /></NavLink>
                    </li>

                  </ul>
                </Accordion.Header>

              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  <ul>
                    <li className="deactive"><NavLink to="/admin/societyNews" ><span className="image-nav"><FaRegUser /></span><span className="img-active"><FaRegUser /></span>Society News<i className="fa fa-angle-right" aria-hidden="true" /></NavLink>
                    </li>

                  </ul>
                </Accordion.Header>

              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  <ul>
                    <li className="deactive"><NavLink to="/admin/contentList" ><span className="image-nav"><FaRegUser /></span><span className="img-active"><FaRegUser /></span>ContentList<i className="fa fa-angle-right" aria-hidden="true" /></NavLink>
                    </li>

                  </ul>
                </Accordion.Header>

              </Accordion.Item>


            </Accordion>
          </div>
        </div>
      </aside>
      <Outlet />
    </React.Fragment>
  )
}

export default SideNav;